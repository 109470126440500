import React, { createContext, FC, FunctionComponent, ReactNodeArray } from "react";
import { useImmer } from 'use-immer';
import { enableMapSet } from 'immer'
import { FlowSegmentInterface } from "./flowSegment";
import { NavLink, BrowserRouter as Router, Link } from "react-router-dom";
import { FlowProps } from "./Flow";

enableMapSet()

export interface FlowsSharedContextInterface {
    config: Map<string, object>;
    data: Map<any, any>;
    readonly update: (key: any, value: any, store?: string) => any;
    links: ReactNodeArray;
    navigationMap: Map<any, any>;
};
export interface FlowsPropsInterface extends FunctionComponent {
    children?: React.ReactNodeArray;
    renderNavigationBeforeContentFunction?: (links: React.ReactNodeArray) => JSX.Element;
    renderNavigationAfterContentFunction?: (links: React.ReactNodeArray) => JSX.Element;
    renderNavigationBeforeContent: false;
    renderNavigationAfterContent: false;
};

const contextDefaultValues: FlowsSharedContextInterface = {
    config: new Map(),
    data: new Map(),
    update: () => { },
    links: [],
    navigationMap: new Map(),
}

export const FlowsSharedContext = createContext<FlowsSharedContextInterface>(
    contextDefaultValues
);

const parseFlowChildren = (children: React.ReactNodeArray): { links: React.ReactNodeArray, navMap: Map<any, any> } => {
    let links: React.ReactNodeArray = [];
    let navMap: Map<any, {}> = new Map();

    if (children) {
        var childrenElements = children instanceof Array ? children : new Array(children);

        childrenElements.forEach((child: React.ReactElement<FlowProps>) => {

            if (!child.props.children) return;

            var flowSegments = child.props.children instanceof Array ? child.props.children : new Array(child.props.children);

            flowSegments.forEach((child: React.ReactElement<FlowSegmentInterface>) => {

                var linkType: React.ComponentType<any> = (child.props.useLink) ? Link : NavLink;

                links.push(
                    React.createElement(linkType, Object.assign(
                        {},
                        child.props.linkProps,
                        {
                            key: child.props.name,
                            to: child.props.link?.toString(),
                            children: child.props.name
                        }))
                );
            });
            navMap.set(child.props.name, { nav: links });

        })
    }
    return { links: links, navMap: navMap };
}

export const Flows: FC = (props: FlowsPropsInterface) => {

    const [flowsData, setFlowsData] = useImmer<Map<any, any>>(contextDefaultValues.data);
    const [configs, setConfigs] = useImmer<Map<string, object>>(contextDefaultValues.config);

    const updateData = (key: any, value: any, store?: string) => {
        if (store === 'config') {
            setConfigs(draft => {
                draft.set(key, value);
            });
        } else {
            setFlowsData(draft => {
                draft.set(key, value);
            });
        }
    }

    const content = parseFlowChildren(props.children as ReactNodeArray);
    const navigationMap = content.navMap;

    const defaultNavigationRenderFunction = (links: React.ReactNodeArray, className: string) => {
        return <nav className={className} > {links} </nav>
    }

    const _renderNavigationBeforeContentFunction = (props.renderNavigationBeforeContentFunction) ? props.renderNavigationBeforeContentFunction : defaultNavigationRenderFunction
    const _renderNavigationAfterContentFunction = (props.renderNavigationAfterContentFunction) ? props.renderNavigationAfterContentFunction : defaultNavigationRenderFunction

    return (
        <FlowsSharedContext.Provider
            value={{
                config: configs,
                data: flowsData,
                update: updateData,
                links: content.links,
                navigationMap,
            }}
        >
            <Router>
                {(props.renderNavigationBeforeContent) ?
                    <>{_renderNavigationBeforeContentFunction(content.links, "navigation-before-content")}</> : <></>}
                {props.children}
                {(props.renderNavigationAfterContent) ?
                    <>{_renderNavigationAfterContentFunction(content.links, "navigation-after-content")}</> : <></>}
            </Router>

        </FlowsSharedContext.Provider>
    );
};