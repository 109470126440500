import * as React from "react";
import { Slice } from '@reduxjs/toolkit'
import { useStore } from "react-redux";
import { FlowReduxStoreInterface } from "./flowReduxStore";
import { FlowsSharedContext } from "./Flows";

export interface FlowComponentInterface {
  slice: Slice
}

export const flowComponentWrapper = (key: string, reducer: any, globalStateIndex?: string | undefined) => (WrappedComponent: React.ComponentType) => {
  const Extended = (props: JSX.IntrinsicAttributes) => {
    const store = useStore() as FlowReduxStoreInterface;

    store.injectReducer(key, reducer);
    const flowsContext = React.useContext(FlowsSharedContext);

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(flowsContext.data.get(globalStateIndex));
      console.log(store.getState());
    }

    return <WrappedComponent {...props} />
  };
  return Extended;
}

export default flowComponentWrapper;