import * as React from "react";
import { NavLinkProps, RouteComponentProps } from "react-router-dom";

export interface FlowSegmentInterface extends RouteComponentProps {
  name?: string
  link?: string
  linkProps?: NavLinkProps
  useLink?: boolean
  children?: React.ReactNode
  className?: string
  
  component: React.ComponentType<any>;
}

export const FlowSegment = (props: FlowSegmentInterface) => {
  React.useEffect(() => {
    console.log(`> Router ${props.history.action} => ${props.history.location}`);
  }, [props.history.location.key])
  React.useEffect(() => {
    console.log(`> location ${location}`);
  })

  return (
    React.createElement('div', props)
  );
}

export default FlowSegment;
