import { AnyAction, combineReducers } from "redux";


interface emptyState {
  value: any;
}

//This is to avoid an error when this first loads and there is no reducer provided
export const __emptyReducer = (state: emptyState = { value: null }, action: AnyAction) => {

  switch (action.type) {
    default: return state;
  }
};

/**
 * @param {Object} - key/value of reducer functions
 */
//  const createRed1ucer = (defualtReducer?: {} | undefined, asyncReducers?: any) =>
const createReducer = (asyncReducers?: any | undefined) => 
     combineReducers({
      ...asyncReducers,
      __emptyReducer
    });


export default createReducer;