
import { createStore, Store, applyMiddleware, Middleware, StoreEnhancer, Reducer, PreloadedState } from "redux";
// import { routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension"
import createReducer from "./flowReducer";
export interface FlowReduxStoreInterface extends Store {
    initialReducer: null
    asyncReducers: {}
    injectReducer: (key: string, reducer: any) => FlowReduxStoreInterface
}

const initializeFlowReduxStore = (initialStore?: PreloadedState<any>, initialReducer?: Object | object[] | undefined, middleware?: Middleware[] | undefined, enhancers?: StoreEnhancer<{ dispatch: any }, {}>[] | undefined) => {
    if (middleware !== undefined) {
        enhancers = (enhancers !== undefined) ? [...enhancers, applyMiddleware(...middleware)] : [applyMiddleware(...middleware)];
    }

    const dynamicStore: any & FlowReduxStoreInterface =

        (enhancers !== undefined) ?
            {
                ...createStore(
                    //make sure initialReducer is defined
                    (initialReducer !== undefined) ?
                        createReducer(initialReducer) : createReducer(),
                    initialStore,
                    composeWithDevTools(...enhancers)
                ),
            } : {
                ...createStore(
                    //make sure initialReducer is defined
                    (initialReducer !== undefined) ?
                        createReducer(initialReducer) : createReducer(),
                    initialStore,
                ),
            }

    dynamicStore.asyncReducers = {}

    if (initialReducer !== undefined) {
        var reducerKeys = Object.keys(initialReducer);
        for (var i = 0; i < reducerKeys.length; i++) {
            var key = reducerKeys[i];

            if (typeof initialReducer[key] === 'function') {
                dynamicStore.asyncReducers[key] = initialReducer[key];
            }
        }
    }
    dynamicStore.injectReducer = (key: string | number, reducer: Reducer) => {
        dynamicStore.asyncReducers[key] = reducer;
        dynamicStore.replaceReducer(
            createReducer(dynamicStore.asyncReducers));
        return dynamicStore;
    };
    return dynamicStore;
};

export default initializeFlowReduxStore;