/**
 * @class FlowComponent
 */

import * as React from "react";
import { Provider } from "react-redux";
import { FlowSegmentInterface } from "./flowSegment";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import initializeFlowReduxStore, { FlowReduxStoreInterface } from "./flowReduxStore";
import { ReactNodeArray } from "react";
import { FlowsSharedContext, FlowsSharedContextInterface } from "./Flows";
import { Middleware, Reducer } from "redux";
import { MiddlewareArray } from "@reduxjs/toolkit";
import { componentClassNameWrapper } from "./componentClassNameWrapper";

export interface FlowProps extends RouteComponentProps {
  showTopFlowNav: boolean;
  showBottomFlowNav: boolean;
  name: string;
  children?: React.ReactNodeArray;
  middleware?: Middleware | Middleware[] | undefined;
  additionalReducers?: Reducer | undefined;
};

class FlowComponent extends React.Component<FlowProps, {}[]> {
  currentSegment: any;
  currenPath: string;
  routes: any;
  childrenNav: any;
  FlowsContext: FlowsSharedContextInterface;
  children: ReactNodeArray;
  navigation: ReactNodeArray
  static contextType = FlowsSharedContext;
  name: any;
  storeCurrentState: boolean = true;

  FlowStore: FlowReduxStoreInterface;
  middleware: Middleware[];

  componentWillUnmount() {
    // This is no longer used.  I left it in here to describe what the originial 
    // idea for global contenxt handled by flows 
    // if (!deepEqual(this.FlowsContext.data.get(this.name), this.FlowStore.getState())) {
    //   if (this.storeCurrentState) {
    //     this.FlowsContext.update(this.name, this.FlowStore.getState());
    //   }
    //   this.storeCurrentState = false;
    //   return;
    // }
    // debugger;
    // this.storeCurrentState = false;
  }

  constructor(props: FlowProps, context: FlowsSharedContextInterface) {
    super(props);
    this.name = this.props.name;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(context.data.get(this.name));
    }

    this.FlowsContext = context;
    this.storeCurrentState = true;
    this.children = props.children instanceof Array ? props.children : new Array(props.children);

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      const previousState = this.FlowsContext.data.get(this.name);
      console.log(previousState);
    }

    this.middleware = (props.middleware) ? props.middleware instanceof Array ? props.middleware : [props.middleware] : new MiddlewareArray();
    this.FlowStore = initializeFlowReduxStore(undefined, props.additionalReducers, this.middleware);

    if (this.children) {
      this.routes = this.children.map(
        (child: React.ReactElement<FlowSegmentInterface>) => (
          React.createElement(Route, Object.assign(
            {},
            child.props,
            {
              key:  `route_${this.name}`,
              path: child.props.link?.toString(),
              component: componentClassNameWrapper(child.props.className)(child.props.component)
            }))
        ));
    }
  }

  render() {
    return (
      <Provider store={this.FlowStore}>
        <div className="react-flow-content">
          <Switch>{this.routes}</Switch>
        </div>
      </Provider>
    );
  }
}

export const Flow = FlowComponent;